import {
  getStoryblokApi,
  useStoryblokState,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react'
import Layout from 'components/Layout/Layout'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import {pageTypes} from './[[...slug]]'
import ButtonBlock from 'components/shared/ButtonBlock'
import {useRouter} from 'next/router'
import {SSM} from 'lib/SSM'
import {constructSBParams} from 'util/StoryblokHelper'
import Head from 'next/head'
import {singletonsSlug} from 'util/slugHelpers/singletonsSlugs'
import findAndReplaceString from 'util/findAndReplaceString'
import {GetStaticPropsContext} from 'next'
import {Segment} from 'util/slugHelpers/segment'

export default function Custom404({
  story,
  mainMenu,
  header,
  footer,
  locale,
}: pageTypes) {
  const router = useRouter()

  story = useStoryblokState(story, {language: locale})
  if (!story) {
    return <div>An unknown error occured</div>
  }

  const title = story.content.header
  const text = story.content.text
  const image = story.content.image
  const cta = story.content.cta[0]

  const navigateToHome = () => {
    router.replace('/', '/')
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Layout
        removeHeaderAndFooter={false}
        translatedSlugs={story.translated_slugs}
        mainMenu={mainMenu}
        header={header}
        footer={footer}
        storyTags={story.tag_list}
      >
        <div className="mt-12 flex h-full items-center justify-center">
          <div
            className="flex flex-col px-8 lg:w-[563px] lg:px-0"
            {...storyblokEditable(story.content)}
          >
            <Image
              src={image.filename}
              height={150}
              width={150}
              alt={'404icon'}
            />
            <h1 className="mt-8 font-rubik text-h1 font-bold">{title}</h1>

            <div
              id="not-found-richtext"
              className="mb-6 mt-2 text-lg text-dats-s6"
              dangerouslySetInnerHTML={{__html: renderRichText(text) ?? ''}}
            />

            <div>
              <ButtonBlock
                title={cta.label}
                type="button"
                className="w-full lg:w-auto"
                onClick={navigateToHome}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const getStaticProps = async ({
  params,
  locale,
  preview,
}: GetStaticPropsContext) => {
  let slugArray = params?.slug as string[]
  let slug = slugArray ? slugArray.join('/') : 'home'
  locale = locale ?? 'nl'

  let isStoryblokLivePreview =
    (slug.includes('fromStoryblok') || preview) ?? false
  if (isStoryblokLivePreview) {
    // Remove the fromStoryblok part of the path as it serves
    // no further purpose.
    slug = slug.replace('/fromStoryblok', '')
  }

  let {
    footerIndividualSlug,
    headerIndividualSlug,
    footerProfessionalSlug,
    headerProfessionalSlug,
    mainMenuPrivateSlug,
    mainMenuProfessionalSlug,
    notFoundSlug,
  } = singletonsSlug

  let sbParams = constructSBParams(locale, false)

  const storyblokApi = getStoryblokApi()
  let storyblokData = null
  try {
    let {data} = await storyblokApi.get(`cdn/stories/${notFoundSlug}`, sbParams)
    storyblokData = data
  } catch (err) {
    console.error('SB fetch error: Could not fetch data for 404 page.', err)
  }

  let story = storyblokData ? storyblokData.story : false

  const privateMenu = await SSM.getInstance().getStoryFromCache(
    mainMenuPrivateSlug,
    locale,
  )
  const professionalMenu = await SSM.getInstance().getStoryFromCache(
    mainMenuProfessionalSlug,
    locale,
  )
  let mainMenu = {
    private: privateMenu.story.content,
    professional: professionalMenu.story.content,
  }

  let headerIndividual = await SSM.getInstance().getStoryFromCache(
    headerIndividualSlug,
    locale,
  )
  let headerProfessional = await SSM.getInstance().getStoryFromCache(
    headerProfessionalSlug,
    locale,
  )
  let header = {
    individual: headerIndividual.story,
    professional: headerProfessional.story,
  }

  let footerIndividual = await SSM.getInstance().getStoryFromCache(
    footerIndividualSlug,
    locale,
  )
  let footerProfessional = await SSM.getInstance().getStoryFromCache(
    footerProfessionalSlug,
    locale,
  )
  let footer = {
    individual: footerIndividual.story.content,
    professional: footerProfessional.story.content,
  }

  // TODO: This should be replaced by the actual correct segment, which
  // we don't know here.
  findAndReplaceString(storyblokData, 'common', Segment.particulier)
  findAndReplaceString(
    storyblokData,
    `fr/${Segment.professional}`,
    `fr/${Segment.professionnel}`,
  )
  findAndReplaceString(
    storyblokData,
    `nl/${Segment.professionnel}`,
    `nl/${Segment.professional}`,
  )

  return {
    props: {
      mainMenu: mainMenu,
      footer: footer,
      story: story,
      key: storyblokData ? storyblokData.story.id : false,
      header: header,
      locale: locale,
      ...(await serverSideTranslations(locale, ['common', 'mainmenu'])),
    },
    revalidate: process.env.APP_ENV === 'prd' ? 5 * 60 : 30, // prd
  }
}
